<template>
	<div style="background-color: #fff;">
		<div style="padding: 10px;">
			<buttonPermissions :datas="'batchPost'">
				<el-button type="primary" style="width:90px;" @click="batchPostData">批量发货</el-button>
			</buttonPermissions>
			<buttonPermissions :datas="'batchExport'">
				<el-button style="margin-left: 20px;" @click="batchExportData">导出待发货订单</el-button>
			</buttonPermissions>
		</div>
		<div class="filter-container" style="padding: 10px;">
			<div class="filter-item">
				<label class="label">开始时间:</label>
				<el-date-picker type="datetime" placeholder="开始日期" v-model="orderStartTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				format="yyyy-MM-dd HH:mm:ss" :picker-options="maxOptions"></el-date-picker>~
				<el-date-picker type="datetime" placeholder="结束日期" v-model="orderEndTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				format="yyyy-MM-dd HH:mm:ss" :picker-options="minOptions"></el-date-picker>
			</div>
			<div class="filter-item">
				<label class="label">状态:</label>
				<el-select v-model="stateType" style="width: 200px;margin-right: 10px;">
					<el-option v-for="item in stateTypeList" :key="item.value" :label="item.lable" :value="item.value"></el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<buttonPermissions :datas="'batchSearch'">
					<el-button type="primary" style="width:90px;" @click="searchData">查询</el-button>
				</buttonPermissions>
			</div>
		</div>
		<div class="table-container" style="padding-bottom: 100px;">
			<el-table :data="tableData" style="width: 100%">
				<el-table-column prop="importCode" label="记录编号"></el-table-column>
				<el-table-column prop="createTime" label="操作时间"></el-table-column>
				<el-table-column prop="importTotal" label="发货单数"></el-table-column>
				<el-table-column prop="successCount" label="成功发货单数"></el-table-column>
				<el-table-column prop="statusValue" label="状态"></el-table-column>
				<el-table-column label="操作" width="250px">
					<template slot-scope="scope">
						<div>
							<button-permissions :datas="'showRecordDetail'">
								<div class="table-button" style="color: #007AFF;" @click="showDetailData(scope.row)">查看记录</div>
							</button-permissions>

						</div>

					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page="page.pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.pageSize"
			layout="total, sizes, prev, pager, next, jumper" :total="page.total"></el-pagination>
		</div>
		<!-- 批量发货 -->
		<el-dialog title="批量发货" :visible.sync="importDialogVisible" width="850px">
			<el-upload class="upload-demo" ref="upload" :on-change="beforeUpload" :action="fileApi + 'admin/order/deliver/batch'" :on-preview="handlePreview" :on-remove="handleRemove"
			:file-list="fileList" :auto-upload="false" :limit="1">
				<div style="display: flex;align-items: center;margin-bottom: 10px;">
					<div style="color: #f00;">*</div>文件
					<el-button slot="trigger" size="small" type="primary" style="margin-left: 10px;">选取文件</el-button>
				</div>
				<div slot="tip" class="el-upload__tip" style="display: flex;align-items: center;">
					<div style="color: #f00;">*</div>最大支持 10000 条记录（支持 csv、xls、xlsx，文件大小请控制在 3MB 以内）
				</div>
			</el-upload>
			<div style="margin: 20px 0;font-size: 12px;display: flex;align-items: center;color: #999;">
				<div style="color: #f00;">*</div>请仔细填写物流公司及快递单号
			</div>
			<div style="text-align: center;">
				<el-button @click="importDialogVisible=false" style="width:120px">取 消</el-button>
				<el-button type="primary" @click="submitSends()" style="width:120px">确认发货</el-button>
			</div>
		</el-dialog>
		<!-- 记录明细 -->
		<el-dialog title="记录明细" :visible.sync="detailDialogVisible" width="80%">
			<div class="table-container" style="padding-bottom: 30px;">
				<div>
					<el-table :data="recordData" style="width: 100%">
						<el-table-column prop="orderNo" width="200px" label="订单编号"></el-table-column>
						<el-table-column prop="goodsName" width="300px" label="商品名称"></el-table-column>
						<el-table-column prop="logisticsCompany" label="物流公司"></el-table-column>
						<el-table-column prop="expressNo" label="物流单号"></el-table-column>
						<el-table-column prop="statusValue" label="处理结果"></el-table-column>
						<el-table-column prop="failReason" label="描述"></el-table-column>
					</el-table>
				</div>
				<el-pagination v-if="total" style="margin-top:20px;float:right;" @size-change="handleDetailSizeChange"
				@current-change="handleDetailCurrentChange" :current-page="pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		deliverList,
		deliverExport,
		importList
	} from '@/api/goods';
	import utils from '@/utils/util.js';
	import config from '@/config/index';
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				orderStartTime: '',
				orderEndTime: '',
				stateType: null,
				stateTypeList: [{
						lable: '全部',
						value: null
					},
					{
						lable: '处理中 ',
						value: 0
					},
					{
						lable: '已完成',
						value: 1,
					},
				],
				page: {
					total: 0,
					pageNo: 1,
					pageSize: 10
				},
				tableData: [],
				importDialogVisible: false,
				detailDialogVisible:false,
				fileApi: config.BASE_URL,
				fileList: [],
				file:null,
				recordData:[],
				total:0,
				pageNo:1,
				pageSize:10,
				importId:0
			}
		},
		computed: {
			minOptions: function() {
				let limitTime = this.orderStartTime
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.orderEndTime
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString())
							)
						}
					}
				}
			},
		},
		created() {
			this.searchData();
		},
		methods: {
			async searchData() {
				let data = {
					pageNo: this.page.pageNo,
					pageSize: this.page.pageSize,
					endTime: this.orderEndTime,
					startTime: this.orderStartTime,
					status: this.stateType,
				};
				let res = await deliverList(data);
				if (res.success) {
					res.data.records.map(item => {
						item.statusValue = item.status == 0 ? '处理中' : '已完成';
						return item;
					})
					this.tableData = res.data.records;
					this.page.total = res.data.total;
				}
			},
			async batchPostData() {
				this.file=null;
				this.importDialogVisible = true;
			},
			async submitSends(){
				if(!this.file){
					this.$message({
						showClose: true,
						type: 'warning',
						message: '请选择文件!'
					});
					return;
				}
				this.$refs.upload.submit();
				var that = this;
				setTimeout(function(){
					that.searchData();
					that.importDialogVisible = false;
				},1500)
			},
			async batchExportData() {
				let data = {
					isDownload:true
				};
				let res = await deliverExport(data);
				utils.convertRes2Blob(res);
			},
			async showDetailData(row) {
				if(row){
					this.importId = row.id;
				}
				this.detailDialogVisible = true;
				let data = {
					pageNo: this.pageNo,
					pageSize: this.pageSize,
					importId: this.importId
				};
				let res = await importList(data);
				if(res.success){
					res.data.records.map(item=>{
						item.statusValue = item.status == 0 ? '失败' : '成功';
						return item;
					})
					this.recordData = res.data.records;
					this.total = res.data.total;
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.pageSize = val;
				this.searchData();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.pageNo = val;
				this.searchData();
			},
			// 切换显示条数
			handleDetailSizeChange(val) {
				this.pageSize = val;
				this.showDetailData();
			},
			// 翻页
			handleDetailCurrentChange(val) {
				this.pageNo = val;
				this.showDetailData();
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
				this.file=null;
			},
			handlePreview(file) {
				console.log(file)
			},
            //文件上传限制
            beforeUpload(file){
                this.file=file;
				console.log(this.file);
                let fileName = file.name.split('.');
                console.log(fileName);
                const isXLS = fileName[1] === 'csv' || fileName[1] === 'xls' || fileName[1] === 'xlsx'
				const isLt3M = file.size / 1024 / 1024 < 3;
				if (!isXLS) {
					this.$message.error('上传文件必须是csv、xls、xlsx格式!');
                    this.fileList.pop();
                    return false;
				}
				if (!isLt3M) {
					this.$message.error('大小请控制在3M以内!');
                    this.fileList.pop();
                    return false;
				}
            }
		}
	}
</script>

<style lang="less">
</style>
